/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 0.4s cubic-bezier(0.04, 0.7, 0.03, 1);
}

@font-face {
    font-family: BT Sport;
    src: url("assets/fonts/BTSport_Bd.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

:root {
    /* --background-color: #ffffff; */
    --title-font-color: #393939;
    --title-font-size: 2rem;
    --subtitle-color: #5b5b5b;
    --subtitle-font-size: 1rem;
    --scale-factor: 1;
    --thumbnail-image-aspect-ratio: 0.5;
    --thumbnail-text-background-colour: #020e32;
    --thumbnail-background-url: url("assets/img/icons/UCLGenericBackground.png");
    --team-1-logo-url: url("assets/img/icons/ManchesterCity.png");
    --team-2-logo-url: url("assets/img/icons/Chelsea.png");
    --competition-logo-url: url("assets/img/icons/UCLCompetitionLogo.png");
}

html,
body {
    background-color: #ffffff;
    padding: 0;
    margin: 0;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 400;
    overflow: hidden;
    color: #3d3c38;
    font-size: 0.9rem;
}


/* .mainTitleWrapper h1, */

.contentTitleWrapper h2 {
    color: var(--title-font-color);
}

.contentDescriptionWrapper p,
.mainDescriptionWrapper p,
.contentTimeFirstBlock p {
    color: var(--subtitle-color);
}


/* Data Table related css */

table {
    width: 100% !important;
    padding-top: 60px !important;
    padding-bottom: 30px !important;
}

table.dataTable.no-footer {
    border-bottom: none !important;
}

td {
    text-align: center;
}

tbody>tr>td {
    background: #ededed;
}

td:last-child,
#tableList>tbody>tr>td:nth-last-child(2) {
    background: #fff
}

table.dataTable thead th {
    border-bottom: none !important;
}

table.dataTable thead td {
    border-bottom: 0.5px solid #d2d2d2 !important;
}

table.dataTable thead th {
    border-top: 1px solid #D2D2D2 !important;
}

table.dataTable.row-border tbody tr:last-child td {
    border-bottom: 1px solid #D2D2D2 !important;
}

table.dataTable.row-border tbody tr td:last-child,
table.dataTable.row-border thead tr th:last-child {
    border-right: 1px solid #D2D2D2 !important;
}

table.dataTable.row-border tbody tr td:first-child,
table.dataTable.row-border thead tr th:first-child {
    border-left: 1px solid #D2D2D2 !important;
}

table>tbody>tr:last-child>td:last-child,
table>thead>tr:last-child>th:last-child {
    border-right: 0.5px solid #D2D2D2 !important;
}

table.dataTable.row-border tbody td {
    border-top: 0.5px solid #D2D2D2 !important;
}

table.dataTable.row-border thead tr th:first-child {
    border-top-left-radius: 4px !important;
}

table>thead>tr>th,
table>tbody>tr>td {
    font-weight: 700;
    color: #000000;
}

table>thead>tr>th,
table>tbody>tr>td {
    border-right: 0.5px solid #D2D2D2;
}

table.dataTable thead table>thead>tr>th:first-child {
    border-top-left-radius: 4px;
}

table.dataTable thead .sorting {
    background-image: url(./assets/img/icons/sort-inactive.svg) !important;
}

table.dataTable thead .sorting_asc {
    background-image: url(./assets/img/icons/sort-active.svg) !important;
}

table>thead>tr>th:last-child {
    border-top-right-radius: 4px;
}

table>tbody>tr:last-child>td:first-child {
    border-bottom-left-radius: 4px;
}

table>tbody>tr:last-child>td:last-child {
    border-bottom-right-radius: 4px;
}

table.dataTable thead th,
table.dataTable thead td {
    padding: 10px 25px !important;
}


/* .dataTables_scrollHead>.dataTables_scrollHeadInner>table {
    opacity: 0 !important;
}*/

.dataTables_sizing {
    /* height: auto !important; */
}

#channelTable>tbody>tr>td:nth-last-child(3),
#channelTable>tbody>tr>td:nth-last-child(2) {
    font-weight: 400 !important;
}

.edit-button,
.select-button {
    border: none;
    background: transparent;
    cursor: pointer;
}

.error-msg {
    color: #e60050;
    margin: 0;
}

p {
    font-size: 0.8rem;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
}

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

textarea:focus,
input:focus {
    outline: none;
}

.colorpick-eyedropper-input-trigger {
    display: none;
}


/* uikit model */

.uk-modal-full {
    padding: 0;
    background: 0 0;
}

@media (min-width: 960px) {
    .uk-modal {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (min-width: 640px) {
    .uk-modal {
        padding: 50px 30px;
    }
}

.uk-modal-body {
    color: #393939;
    font-weight: 700;
    font-size: 2rem;
}

.uk-button-primary {
    background: #5514b4 !important;
    border: 0.5px solid #5514b4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0.4rem 1rem;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 0.9rem;
    cursor: pointer;
    margin: 0 0.5rem;
    height: 2.4rem;
}

.uk-modal-close {
    display: flex;
    justify-items: center;
    padding: 0.4rem 1rem;
    border-radius: 4px;
    background: #ededed;
    border: 0.5px solid #D2D2D2;
    font-family: Poppins;
    font-weight: 700;
    color: #2F2F2F;
    align-items: center;
    height: 2.4rem;
    margin: 0 0.5rem;
    cursor: pointer;
}

.uk-modal-close>img {
    margin-right: 0.3rem;
}

.uk-button:focus-visible {
    outline: none !important;
}

.uk-button-primary>img {
    filter: invert(1);
    margin-right: 0.3rem;
}

.e-daterangepicker.e-popup {
    border-radius: 4px;
}

.uk-modal-footer {
    margin-top: 1rem;
}

.uk-button,
.uk-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row-reverse;
}

.uk-modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1010;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0px;
    background: rgba(255, 255, 255, 0.72);
    backdrop-filter: blur(200px);
    opacity: 0;
    transition: opacity 0.15s linear;
}

#uk-button {
    opacity: 0;
}

.uk-modal.uk-open {
    opacity: 1 !important;
    backdrop-filter: blur(40px);
    background: rgba(255, 255, 255, .2);
}

.uk-modal-full .uk-modal-dialog {
    margin: 0;
    width: 100%;
    max-width: 100%;
    transform: translateY(0);
}

.uk-modal-dialog {
    position: relative;
    box-sizing: border-box;
    margin: 0 auto;
    width: 600px;
    max-width: calc(100% - 0.01px) !important;
    opacity: 1;
    transition: 0.3s linear;
    transition-property: opacity, transform;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

button.uk-icon:not(:disabled) {
    cursor: pointer;
}

.uk-modal-close-full {
    top: 0;
    right: 0;
    padding: 20px;
}

.uk-close {
    color: #999;
    transition: 0.1s ease-in-out;
    transition-property: color, opacity;
}

[class*="uk-modal-close-"]:first-child+* {
    margin-top: 0;
}

.uk-flex-middle {
    align-items: center;
}

.uk-grid-collapse,
.uk-grid-column-collapse {
    margin-left: 0;
}

@media (min-width: 1200px) {
    .uk-grid {
        margin-left: -40px;
    }
}

.uk-grid {
    margin-left: -30px;
}

.uk-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}

.uk-background-cover {
    background-size: cover;
}

.uk-background-contain,
.uk-background-cover,
.uk-background-height-1-1,
.uk-background-width-1-1 {
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

@media (min-width: 640px) {
    .uk-child-width-1-2\@s >* {
        width: 50%;
    }
}

[class*="uk-child-width"]>* {
    box-sizing: border-box;
    width: 100%;
}

.uk-grid-collapse>*,
.uk-grid-column-collapse>* {
    padding-left: 0;
}

@media (min-width: 1200px) {
    .uk-grid>* {
        padding-left: 40px;
    }
}

.uk-grid>* {
    padding-left: 30px;
}

.uk-grid>* {
    margin: 0;
}

@media (min-width: 1200px) {
    .uk-padding-large {
        padding: 70px;
    }
}

.uk-padding-large {
    padding: 30px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.logo {
    max-width: 400px;
}

.dataTables_wrapper .dataTables_processing {
    padding-top: 8px !important;
}

.dataTables_length {
    position: absolute;
    right: 0px;
}

.dataTables_filter {
    position: absolute;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dataTables_wrapper .dataTables_filter input {
    width: 30rem;
    height: 2.4rem !important;
    font-size: 15px;
    border: 1px solid #d2d2d2 !important;
    border-radius: 4px !important;
    margin-left: 0.5rem;
}

.removeCriteria {
    height: 17px;
}

.dataTables_wrapper .dataTables_filter label {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dataTables_wrapper .dataTables_length label {
    font-weight: 700;
}

select {
    /* width: 172px; */
    height: 2.4rem;
    padding: 2px 7px;
    border: none;
    /* background: url(http://imgur.com/MJyZM.png) 0 0 no-repeat; */
}

table.dataTable tbody tr {
    background-color: transparent !important;
}

#ingestHistory>tbody td {
    padding: 0 !important;
    background: #ededed;
}

#ingestHistory thead {
    display: none !important;
}

#ingestHistory>tbody>tr>.historyData {
    color: #5B5B5B !important;
}

#ingestHistory {
    padding-top: 0px !important;
    border-collapse: separate !important;
    border-spacing: 0 1em !important;
}

#ingestHistory.dataTable.row-border tbody tr td:nth-child(2),
#ingestHistory.dataTable.row-border tbody tr td:nth-child(3) {}

#ingestHistory.dataTable.row-border tbody td {
    border-top: 1px solid rgba(0, 0, 0, 0.16) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16) !important;
    border-left: none !important;
    border-right: none !important;
}

#ingestHistory.dataTable.row-border tbody td:first-child {
    border-left: 1px solid rgba(0, 0, 0, 0.16) !important;
}

#ingestHistory.dataTable.row-border tbody td:last-child {
    /* border-top: none !important;
    border-bottom: none !important; */
    border-right: 1px solid rgba(0, 0, 0, 0.16) !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

#ingestHistory.dataTable.row-border tbody tr td:nth-child(3) {
    /* border-top: none !important;
    border-bottom: none !important; */
    /* border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important; */
}

#ingestHistory.dataTable.row-border tbody tr td:first-child {
    border-right: none !important;
    /* color: #5b5b5b; */
    font-weight: bold;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    border-left: 1px solid rgba(0, 0, 0, 0.16);
}

#ingestHistory.dataTable.row-border tbody tr td.no-data-available {
    padding: 1rem !important;
    border-right: 1px solid rgba(0, 0, 0, 0.16) !important;
}

#ingestHistory.dataTable.row-border tbody tr.odd {
    display: none !important;
}


/* #ingestHistory.dataTable.row-border tbody tr td:nth-child(3)>span {
    color: #5b5b5b;
    font-weight: 400;
} */

#ingestHistory.dataTable tbody td:first-child,
#ingestHistory.dataTable tbody td:nth-child(2) {
    /* padding: 6px 10px !important; */
    padding: 0px !important;
}

.color-picker .type-policy,
#slotDuration span.e-input-group-icon.e-date-icon.e-icons,
#slotStartTime span.e-input-group-icon.e-date-icon.e-icons,
#slotDate span.e-input-group-icon.e-time-icon.e-icons {
    display: none !important;
}

.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-input-group,
.e-float-input.e-control-wrapper,
.e-float-input.e-input-group.e-control-wrapper {
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
    padding: 0.6rem 1rem;
    width: inherit;
    border: 0.5px solid #e6e6e6 !important;
    border-radius: 4px;
    font-size: 1rem;
    transition: all 0.4s cubic-bezier(0.04, 0.7, 0.03, 1);
    margin: 0;
    padding: 0.23rem 1rem;
    height: 2.4rem;
}

.e-daterangepicker.e-popup .e-calendar .e-content .e-range-hover span {
    background: #D2D2D2;
}

.e-calendar .e-content td,
.e-calendar .e-content th {
    border: 0.5px solid #D2D2D2;
}

#customDate>.e-input-group,
.e-input-group.e-control-wrapper {
    border: 0.5px solid #D2D2D2 !important;
}

.e-input-group-icon.e-range-icon,
*.e-control-wrapper .e-input-group-icon.e-range-icon {
    margin: 0;
    color: #3d3c38;
}

.e-daterangepicker {
    right: 4rem !important;
    left: auto !important;
}


/* .e-clear-icon {
    position: absolute;
    right: 38px;
    top: 2px;
} */


/* ejs-datetimepicker */

#slotStartTime,
#slotDate {
    width: 100% !important;
}

#slotDuration {
    width: 49.6% !important;
}

#datetimepicker {
    width: 70% !important;
}

.e-datetime-wrapper .e-input-group-icon.e-date-icon,
.e-datetime-wrapper .e-input-group-icon.e-time-icon {
    min-height: 15px !important;
    margin-bottom: 0;
}

.e-control input.e-input,
.e-control .e-input-group input,
.e-control .e-input-group input.e-input,
.e-control .e-input-group.e-control-wrapper input,
.e-control .e-input-group.e-control-wrapper input.e-input {
    padding: 0 !important;
}

.e-input-group input.e-input,
.e-input-group.e-control-wrapper input.e-input {
    min-height: auto !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before {
    background: transparent !important;
}

.e-input-group .e-clear-icon,
.e-input-group.e-control-wrapper .e-clear-icon {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.e-datetime-wrapper .e-input-group-icon.e-icons.e-active {
    color: #e60050 !important;
}

input.e-input::selection {
    background: transparent !important;
    color: #000 !important;
}

.switch {
    position: relative;
    display: inline-block;
    margin-left: .6rem;
    border-radius: 120px;
    width: 2.3rem;
    height: 1.1rem;
    background: #fff;
    border: .5px solid #e6e6e6;
    box-sizing: border-box;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    left: 0px;
    bottom: 0px;
    background-color: #4F4F4F;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    position: absolute;
    content: "";
    height: 1rem;
    width: 1rem;
    background: #4F4F4F;
    transition: .4s;
}

input:checked+.slider {
    background-color: #fff;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(1.2rem);
    -ms-transform: translateX(1.2rem);
    transform: translateX(1.2rem);
    background-color: #5514b4;
}


/*------ ADDED CSS ---------*/

.on {
    display: none;
}

.on,
.off {
    color: #000;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 25%;
    left: 25%;
    margin: -3% 4%;
    font-size: 0.45rem;
    font-weight: 400;
}

.on {
    top: 8px;
    left: 22%;
}

.off {
    left: auto;
    right: -4px;
    top: 8px;
}

input:checked+.slider .on {
    display: block;
}

input:checked+.slider .off {
    display: none;
}


/*--------- END --------*/


/* Rounded sliders */

.slider.round {
    border-radius: 17px;
}

.slider.round:before {
    border-radius: 50%;
}

#filterDate span.e-input-group-icon.e-time-icon.e-icons {
    display: none !important;
}

.ui-notification {
    height: 3rem;
}

.uk-notification-bottom-center {
    position: absolute;
    bottom: 0px;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 700;
}

.uk-notification-close {
    position: absolute;
    right: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.125rem solid #000000;
    border-radius: 50%;
    filter: invert(1);
    margin: 0.8rem 0rem;
}

.uk-notification-close>svg {
    width: 0.625rem;
    height: 0.625rem;
}

.suggestions-container>ul>li.item>div:hover a {
    color: #ffffff !important;
}

.autocomplete-container .suggestions-container.is-visible>ul {
    border-radius: 4px !important;
}

.autocomplete-container .input-container .x i {
    vertical-align: baseline !important;
    font-size: 20px !important;
}

.ng-autocomplete {
    width: 100% !important;
}

.autocomplete-container .input-container input {
    border: 1px solid #D2D2D2 !important;
    height: 2.4rem !important;
    border-radius: 4px !important;
}

.autocomplete-container {
    box-shadow: none !important;
    height: 2.4rem !important;
}

.suggestions-container>ul>li.item>div {
    background: #ffffff;
    font-weight: 700;
    align-items: center;
    text-align: center;
}

.suggestions-container>ul>li.item>div:hover,
.autocomplete-container .suggestions-container .complete-selected {
    background: #5514B4 !important;
    cursor: pointer;
}

.suggestions-container>ul>li.item>div:hover a,
.autocomplete-container .suggestions-container .complete-selected a {
    color: #ffffff !important;
}

.autocomplete-container .suggestions-container.is-visible {
    border-radius: 4px !important;
}

.autocomplete-container .input-container .x i {
    vertical-align: baseline !important;
    font-size: 20px !important;
}


/* Pagination */

.dataTables_wrapper .dataTables_paginate,
.dataTables_wrapper .dataTables_paginate span {
    display: flex;
    align-items: center !important;
}

.dataTables_wrapper .dataTables_paginate span {
    padding: 0 1.5rem;
}

.dataTables_wrapper .dataTables_paginate span img {
    width: 0.49rem;
    height: 0.75rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: #ededed !important;
    border: 1px solid rgba(0, 0, 0, 0.16) !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    height: 2.4rem !important;
    width: 2.4rem !important;
    padding: 1rem !important;
    margin: 0 0.25rem !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    color: #393939 !important;
    font-weight: 700;
    font-size: 1rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
    box-shadow: none !important;
}

.cardsTable>.dataTables_wrapper .dataTables_filter {
    display: none !important;
    margin: 0.75rem 0rem;
}

input::-webkit-input-placeholder {
    color: #3D3C38;
    font-size: 1rem;
    font-weight: 400;
}

input:-moz-placeholder {
    color: #3D3C38;
    font-weight: 400;
    font-size: 1rem;
}