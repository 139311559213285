table.dataTable thead .sorting_asc {
    background-image: url(../img/icons/sort-active.svg) !important;
}

table.dataTable thead .sorting_desc {
    background-image: url(../img/icons/sort-desc-active.svg) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button {
    font-weight: 700;
    border-radius: 8px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
    background: #E60050 !important;
    color: #fff !important;
    border: none !important
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    background: #EDEDED !important;
    border: 1px solid rgba(0, 0, 0, 0.16) !important;
    box-sizing: border-box !important;
    color: #393939 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background: #5514b4 !important;
    transform: scale3d(1.1, 1.1, 1.1) !important;
    box-shadow: 0px 8px 8px rgb(85 20 180 / 40%);
    border: 0.5px solid transparent !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover span>img,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover span>img {
    filter: invert(1) !important;
}

.primaryButtonColored:focus-visible,
.primaryButtonGray:focus-visible,
.primaryButtonWhite:focus-visible,
.primaryButtonColored:focus-visible {
    outline: none;
}